import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout/Layout';
import Astro404 from '../assets/videos/404-Astro.mp4';

const NotFound = () => (
  <Layout
    description="At LawAdvisor, our people and their development come first. We believe that the company’s success depends on the ability of our people to learn together and produce new ideas."
    image="/metaimages/meta-image-la-ventures.png"
    pageName="404"
  >
    <div className="page404 mx-auto">
      <video
        className="position-relative mx-auto"
        autoPlay
        muted
        loop
        width="100%"
      >
        <source src={Astro404} type="video/mp4" />
      </video>
      <p>
        We can&apos;t seem to find the page
        <br />
        you&apos;re looking for.
      </p>
      <Link to="/">
        <button className="btn btn-outline-primary">Go back to homepage</button>
      </Link>
    </div>
  </Layout>
);

export default NotFound;
